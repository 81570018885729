<template>
  <div class="cover-credits">
    <div class="cover-credits__disclaimer">Copyright mit Lizenzen der Reihe nach von oben links nach unten rechts. Photos sind nachträglich bearbeitet.</div>

    <div v-for="(credit, index) in creditsMobile" :key="credit.key" class="cover-credits__credit cover-credits__credit--mobile">
      <div class="cover-credits__credit-index">{{ index + 1 }}</div>
      <a class="cover-credits__credit-attribution" target="_blank" rel="noreferrer noopener" :href="credit.attributionUrl">{{ credit.attribution }}</a>
      <a class="cover-credits__credit-license" target="_blank" rel="noreferrer noopener" :href="credit.licenseUrl">{{ credit.license }}</a>
      <div class="cover-credits__credit-title">{{ credit.title }}</div>
    </div>

    <div v-for="(credit, index) in creditsDesktop" :key="credit.key" class="cover-credits__credit cover-credits__credit--desktop">
      <div class="cover-credits__credit-index">{{ index + 1 }}</div>
      <a class="cover-credits__credit-attribution" target="_blank" rel="noreferrer noopener" :href="credit.attributionUrl">{{ credit.attribution }}</a>
      <a class="cover-credits__credit-license" target="_blank" rel="noreferrer noopener" :href="credit.licenseUrl">{{ credit.license }}</a>
      <div class="cover-credits__credit-title">{{ credit.title }}</div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'CoverCredit',
  data(){
    return {
      orderMobile: ['bundespolizei', 'nuesslein', 'amthor', 'scheuer', 'spahn', 'leyen', 'maassen', 'seehofer', 'laschet', 'reichstag'],
      orderDesktop: ['bundespolizei', 'nuesslein', 'leyen', 'seehofer', 'amthor', 'laschet', 'scheuer', 'maassen', 'spahn', 'reichstag'],
      credits: [
        {
          id: 'bundespolizei',
          attribution: 'Kevin Hackert',
          attributionUrl: 'https://www.flickr.com/photos/kevinhackert/35733543291/',
          license: 'CC BY-NC 2.0',
          licenseUrl: 'https://creativecommons.org/licenses/by-nc/2.0/',
          title: 'G20 Germany: Bundespolizei | Airbus Helicopters H155 EC55 | D-HLTF | MSN 6562'
        }, {
          id: 'nuesslein',
          attribution: 'Marta Ifrim',
          attributionUrl: 'https://commons.wikimedia.org/wiki/File:N%C3%BC%C3%9Flein_Georg_2017_by_Marta_Ifrim.jpg',
          license: 'CC BY-SA 3.0',
          licenseUrl: 'https://creativecommons.org/licenses/by-sa/3.0/',
          title: 'Nüßlein Georg 2017 by Marta Ifrim'
        }, {
          id: 'maassen',
          attribution: 'Opposition 24',
          attributionUrl: 'https://www.flickr.com/photos/128406688@N07/49083737001',
          license: 'CC BY 2.0',
          licenseUrl: 'https://creativecommons.org/licenses/by/2.0/',
          title: 'Hans-Georg Maaßen'
        }, {
          id: 'seehofer',
          attribution: 'Olaf Kosinsky (kosinsky.eu)',
          attributionUrl: 'https://kosinsky.eu/',
          license: 'CC BY-SA 3.0',
          licenseUrl: 'https://creativecommons.org/licenses/by-sa/3.0/',
          title: '2018-11-30 Horst Seehofer Innenministerkonferenz in Magdeburg-2310'
        }, {
          id: 'amthor',
          attribution: 'Olaf Kosinsky',
          attributionUrl: 'https://commons.wikimedia.org/wiki/File:2019-04-11_Philipp_Amthor_CDU_MdB_by_Olaf_Kosinsky-9083.jpg',
          license: 'CC BY-SA 3.0',
          licenseUrl: 'https://creativecommons.org/licenses/by-sa/3.0/',
          title: '2019-04-11 Philipp Amthor CDU MdB by Olaf Kosinsky-9083'
        }, {
          id: 'laschet',
          attribution: 'Dirk Vorderstraße',
          attributionUrl: 'https://www.flickr.com/photos/dirkvorderstrasse/50360277976',
          license: 'CC BY 2.0',
          licenseUrl: 'https://creativecommons.org/licenses/by/2.0/',
          title: 'Armin Laschet (CDU), Ministerpräsident von Nordrhein-Westfalen'
        }, {
          id: 'scheuer',
          attribution: 'BMVI Besucher',
          attributionUrl: 'https://www.flickr.com/photos/bmvi_besucher/29734064477/in/album-72157701314349795/',
          license: 'CC BY-NC 2.0',
          licenseUrl: 'https://creativecommons.org/licenses/by-nc/2.0/',
          title: 'BM Scheuer empfängt Schülerinnen und Schüler der 12. Klasse vom Maristengymnasium in Fürstenzell'
        }, {
          id: 'leyen',
          attribution: 'Dirk Vorderstraße',
          attributionUrl: 'https://www.vorderstrasse.de/fotos/details/ursula-von-der-leyen-cdu-bundesverteidigungsministerin/11951/-/',
          license: 'CC BY-NC 3.0',
          licenseUrl: 'https://creativecommons.org/licenses/by-nc/3.0/',
          title: 'Ursula von der Leyen (CDU), Bundesverteidigungsministerin'
        }, {
          id: 'spahn',
          attribution: 'Raimond Spekking',
          attributionUrl: 'https://commons.wikimedia.org/wiki/File:Vorstellung_der_Plakatkampagne_Henriette_Reker_zur_Oberb%C3%BCrgermeisterinnen-Wahl_2020_-0533.jpg',
          license: 'CC BY-SA 4.0',
          licenseUrl: 'https://creativecommons.org/licenses/by-sa/4.0/',
          title: 'Vorstellung der Plakatkampagne Henriette Reker zur Oberbürgermeisterinnen-Wahl 2020 -0533'
        }, {
          id: 'reichstag',
          attribution: 'Eric Pancer',
          attributionUrl: 'https://en.wikipedia.org/wiki/File:Vxla-berlin-reichstag-open-air-dome.jpg',
          license: 'CC BY 3.0',
          licenseUrl: 'https://creativecommons.org/licenses/by/3.0/',
          title: 'Vxla-berlin-reichstag-open-air-dome'
        }
      ]
    }
  },
  computed: {
    creditsMobile(){
      return this.orderMobile.map(id => this.credits.find(credit => credit.id === id))
    },
    creditsDesktop(){
      return this.orderDesktop.map(id => this.credits.find(credit => credit.id === id))
    }
  }
}
</script>

<style scoped lang="sass">

.cover-credits
  margin-top: 10px
  font-size: 10px
  color: #bbb
  line-height: 1

  &__disclaimer
    margin-bottom: 2px

  &__credit
    display: inline-flex
    padding-right: 5px
    flex-wrap: wrap

    a
      border-bottom: 1px solid transparent

    &:hover
      color: #fff

      a
        border-bottom-color: white
    
    &--mobile
      @media (min-aspect-ratio: 1/1)
        display: none

    &--desktop
      display: none
      @media (min-aspect-ratio: 1/1)
        display: inline-flex


  &__credit-index
    border: 1px solid currentColor
    border-radius: 10px
    display: inline-block
    width: 10px
    height: 10px
    display: flex
    align-items: center
    justify-content: center
    font-size: 8px

  &__credit-attribution,
  &__credit-license,
  &__credit-title
    text-decoration: none
    color: inherit
    margin-left: 5px
    display: inline

</style>
